<template>
  <el-dialog
    :visible.sync="visibleLocal"
    class="ClippingBulkTagManagerDialog"
    :title="$t('components.clipping_bulk_tag_manager_dialog.title')"
    :before-close="resetForm"
  >
    <form-field :label="$t('components.clipping_bulk_tag_manager_dialog.add_new_tags_label')">
      <clipping-tags-picker
        v-model="form.tags_to_add"
        :brand-id="activeBrandId"
      />
    </form-field>
    <form-field :label="$t('components.clipping_bulk_tag_manager_dialog.remove_tags_label')">
      <tags-list
        #default="{ tag }"
        remove-only
        :value="anyTagApplied"
        data-testid="removeTagsInput"
        @remove="handleTagRemove"
      >
        {{ tag.title }}
      </tags-list>
    </form-field>
    <template #footer>
      <v-button
        :loading="isSavingTags"
        :disabled="isButtonDisabled"
        class="is-black"
        data-testid="submitTagsTrigger"
        @click.prevent="submitTags"
      >
        {{ $t('forms.save') }}
      </v-button>
    </template>
  </el-dialog>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'
import ClippingTagsPicker from './ClippingTagsPicker'
import TagsList from '@/components/core/TagsList'
import { ClippingsApi } from '@/services/api/ClippingsApiService'

/**
 * @module ClippingBulkTagManagerDialog
 */
export default {
  name: 'ClippingBulkTagManagerDialog',
  components: { TagsList, ClippingTagsPicker },
  props: {
    selectedClippings: {
      type: Array,
      default: () => ([])
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSavingTags: false,
      form: {
        tags_to_add: [],
        tags_to_remove: []
      }
    }
  },
  computed: {
    visibleLocal: generateComputedSetterWithEmit('visible', 'update:visible'),
    allTags () {
      const aggregated = this.selectedClippings.reduce((all, current) => {
        return all.concat(current.tags.data)
      }, [])
      return aggregated.filter((el, index, arr) => arr.findIndex(t => t.id === el.id) === index)
    },
    anyTagApplied () {
      return this.allTags.filter(t => !this.form.tags_to_remove.includes(t.id))
    },
    isButtonDisabled () {
      return !(this.form.tags_to_remove.length || this.form.tags_to_add.length)
    }
  },
  methods: {
    handleTagRemove (tag) {
      this.form.tags_to_remove.push(tag.id)
    },
    resetForm (next) {
      this.form.tags_to_remove = []
      this.form.tags_to_add = []
      next && next()
    },
    async submitTags () {
      try {
        await this.$confirm(this.$t('components.clipping_bulk_tag_manager_dialog.confirm_tag_modification'))

        this.isSavingTags = true
        await ClippingsApi.bulkUpdateTags(this.activeBrandId, {
          clippings: this.selectedClippings.map(c => c.id),
          ...this.form
        })
        this.$notify.success(this.$t('components.clipping_bulk_tag_manager_dialog.updated_successfully'))
        this.$emit('finish')
        this.resetForm()
        this.visibleLocal = false
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isSavingTags = false
      }
    }
  }
}
</script>
