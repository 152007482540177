<template>
  <page-section
    class="Coverage"
    :page-title="$t('pages.coverage.coverage.sidebar_title')"
  >
    <template #tooltip-content>
      <p
        class="m-b-m"
        v-html="$t('pages.coverage.coverage.sidebar_description_1',{ brand: activeBrand.name})"
      />
      <p>{{ $t('pages.coverage.coverage.sidebar_description_2') }}</p>
    </template>
    <template #title-right>
      <div v-if="selectMultiple" class="columns is-variable-grid is-1 is-aligned-middle">
        <div data-testid="selectedClippingsCount" class="column">
          {{ $t('pages.coverage.coverage.selected_clippings', { count: selectedClippings.length }) }}
        </div>
        <!--          <div class="column is-narrow">-->
        <!--            <v-button-->
        <!--              :disabled="!selectedClippings.length"-->
        <!--              data-testid="bulkEmailClippings"-->
        <!--              class="is-black is-narrow is-plain"-->
        <!--              @click="$router.push({ name: 'emails.new', query: { entity_id: selectedClippings, type: 'clippings' } })"-->
        <!--            >-->
        <!--              <v-icon type="paper-plane" />-->
        <!--            </v-button>-->
        <!--          </div>-->
        <div class="column is-narrow">
          <v-button
            class="is-black is-narrow is-plain"
            :disabled="!selectedClippings.length"
            data-testid="bulkTagClippings"
            @click.prevent="isClippingBulkTagVisible = true"
          >
            <span class="icon">
              <v-icon type="tag" />
            </span>
          </v-button>
        </div>
        <div class="column is-narrow">
          <v-button
            class="is-black is-narrow is-plain"
            :loading="isBulkDeleting"
            :disabled="!selectedClippings.length"
            data-testid="bulkDeleteClippings"
            @click.prevent="bulkDelete"
          >
            <v-icon type="bin" />
          </v-button>
        </div>
      </div>
      <div v-else>
        <v-button
          class="is-black"
          data-testid="enableBulkSelect"
          @click="selectMultiple = true"
        >
          {{ $t('pages.coverage.coverage.select_multiple') }}
        </v-button>
      </div>
    </template>
    <template #above-title>
      <coverage-report-generator show-keyword-search />
    </template>
    <div class="columns">
      <div class="column is-12">
        <div class="ClippingList">
          <load-more
            ref="clippings"
            v-loading="isLoading"
            :endpoint="clippingsEndpoint"
            :placeholder-items="4"
            width="column is-flex is-12-mobile is-6-tablet is-4-desktop is-3-fullhd"
            placeholder="ClippingItemPlaceholder"
            @loading="hasLoaded"
          >
            <template #item="{ item: clipping }">
              <clipping-list-item
                :clipping="clipping"
                @removed="$refs.clippings.removeItem"
              >
                <template v-if="selectMultiple" #icons>
                  <el-checkbox-group v-model="selectedClippings">
                    <el-checkbox :label="clipping" class="ClippingList--BulkSelect" data-testid="ClippingListItem--Select" />
                  </el-checkbox-group>
                </template>
              </clipping-list-item>
            </template>
          </load-more>
        </div>
      </div>
    </div>
    <clipping-bulk-tag-manager-dialog
      :selected-clippings="selectedClippings"
      :visible.sync="isClippingBulkTagVisible"
      @finish="handleBulkTagUpdate"
    />
  </page-section>
</template>

<script>
import { mapGetters } from 'vuex'
import ClippingListItem from '@/components/clippings/ClippingListItem'
import { ClippingsApi } from '@/services/api/ClippingsApiService'
import CoverageReportGenerator from '@/components/performance/CoverageReportGenerator'
import ClippingBulkTagManagerDialog from '@/components/clippings/ClippingBulkTagManagerDialog'

/**
 * Displays the list of clippings inside Measure/Coverage
 * @module Coverage
 */
export default {
  components: {
    ClippingBulkTagManagerDialog,
    CoverageReportGenerator,
    ClippingListItem
  },

  data () {
    return {
      isLoading: true,
      isBulkDeleting: false,
      isClippingBulkTagVisible: false,
      selectMultiple: false,
      selectedClippings: []
    }
  },

  computed: {
    ...mapGetters(['filtersQueryFor', 'activeBrand']),
    clippingsEndpoint () {
      return `clippings?include=country,tags&sort[]=newest&brands[]=${this.activeBrandId}&` + this.filtersQueryFor('coverage')
    }
  },

  methods: {
    hasLoaded (val) {
      this.isLoading = val
    },
    resetSelectedClippings () {
      this.selectedClippings = []
    },
    handleBulkTagUpdate () {
      this.resetSelectedClippings()
      this.$refs.clippings.refresh()
    },
    async bulkDelete () {
      try {
        await this.$confirm(this.$t('pages.coverage.coverage.confirm_delete'))
        this.isBulkDeleting = true
        const clippingIds = this.selectedClippings.map(c => c.id)

        await ClippingsApi.bulkDelete(this.activeBrandId, clippingIds)
        this.$refs.clippings.removeItemsBy(clippingIds)
        this.resetSelectedClippings()
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isBulkDeleting = false
      }
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.ClippingList--BulkSelect {
  .el-checkbox__label {
    display: none;
  }
}
</style>
